import getAllowDownload from 'owa-attachment-model-store/lib/actions/initialization/getAllowDownload';
import type { AttachmentPolicyInfo } from 'owa-attachment-policy';
import { getAttachmentPolicyInfo } from 'owa-attachment-policy';
import { getExtensionFromFileName } from 'owa-file';
import type AttachmentType from 'owa-service/lib/contract/AttachmentType';
import type { MailboxInfo } from 'owa-client-types';

export function isDownloadAllowed(attachment: AttachmentType, mailboxInfo: MailboxInfo) {
    const attachmentPolicyInfo: AttachmentPolicyInfo = getAttachmentPolicyInfo(
        attachment,
        true /* isWacPreviewSupportedOnPlatform */,
        mailboxInfo
    );
    const fileExtension = getExtensionFromFileName(attachment.Name) || undefined;
    return getAllowDownload(attachmentPolicyInfo, attachment, fileExtension);
}
