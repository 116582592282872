import type { Capability } from 'owa-capabilities';
import type { MailboxInfo } from 'owa-client-types';
import { isAnalyzeContentDisabledForPrivacy } from 'owa-privacy-utils/lib/selectors/isAnalyzeContentDisabledForPrivacy';

/**
 * NOTE: Please see the README for more information on how the
 * capability functions must be implemented and used.
 */
export const learningToolsCapability: Capability = {
    isEnabled: (_mailboxInfo?: MailboxInfo) => {
        return true; // by default
    },
    isSupported: (_mailboxInfo?: MailboxInfo) => {
        return !isAnalyzeContentDisabledForPrivacy();
    },
};
