import { LazyImport, LazyModule, registerLazyOrchestrator } from 'owa-bundling';
import { showActionRestrictionDueToPolicy } from './actions/showActionRestrictionDueToPolicy';
import { showUpdateRestrictionOnRegulatoryPolicy } from './actions/showUpdateRestrictionOnRegulatoryPolicy';

// Create lazily loadable RetentionPolicies chunk.
const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "RetentionPolicies" */ './lazyIndex'),
    { name: 'RetentionPolicies' }
);

export const lazyOnArchiveTagForFolderChanged = new LazyImport(
    lazyModule,
    m => m.onArchiveTagForFolderChanged
);

export const lazyOnAssignPolicy = new LazyImport(lazyModule, m => m.onAssignPolicy);

export const lazyOnDeleteTagForFolderChanged = new LazyImport(
    lazyModule,
    m => m.onDeleteTagForFolderChanged
);

export const lazyGetRetentionPolicyMenuItem = new LazyImport(
    lazyModule,
    m => m.getRetentionPolicyMenuItem
);

export const lazyGetAssignPolicyPropertiesForMailContextMenu = new LazyImport(
    lazyModule,
    m => m.getAssignPolicyPropertiesForMailContextMenu
);

export const lazyShouldSkipRequestToServerDueToPolicy = new LazyImport(
    lazyModule,
    m => m.shouldSkipRequestToServerDueToPolicy
);

registerLazyOrchestrator(
    showActionRestrictionDueToPolicy,
    lazyModule,
    m => m.showActionRestrictionDueToPolicyOrchestrator
);

registerLazyOrchestrator(
    showUpdateRestrictionOnRegulatoryPolicy,
    lazyModule,
    m => m.showUpdateRestrictionOnRegulatoryPolicyOrchestrator
);
