import { LazyAction, LazyImport, LazyModule, registerLazyOrchestrator } from 'owa-bundling';
import markItemsAsReadStoreUpdate from 'owa-mail-actions/lib/triage/markItemsAsReadStoreUpdate';
import initializeExtendedCardForItemReadingPane from './actions/initializeExtendedCardForItemReadingPane';

// webpackPrefetch is 2 means that the reading pane package could be prefetched ealier than
// the packages which have webpackPrefetch as true.
export const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "ReadingPaneStore"*/ './lazyIndex'),
    { name: 'ReadingPaneStore' }
);

// Export delay loaded actions
export const lazyPrint = new LazyAction(lazyModule, m => m.print);
export const lazyLoadItemReadingPane = new LazyAction(lazyModule, m => m.loadItemReadingPane);
export const lazyLoadItemReadingPaneForSingleMailItemSelected = new LazyAction(
    lazyModule,
    m => m.loadItemReadingPaneForSingleMailItemSelected
);

// Register lazy orchestrator
registerLazyOrchestrator(
    markItemsAsReadStoreUpdate,
    lazyModule,
    m => m.markItemsAsReadStoreUpdateOrchestrator
);
registerLazyOrchestrator(
    initializeExtendedCardForItemReadingPane,
    lazyModule,
    m => m.initializeExtendedCardForItemReadingPaneOrchestrator
);

export const lazyIsUnsupportedItem = new LazyImport(lazyModule, m => m.isUnsupportedItem);

export const lazyReplyByMeeting = new LazyAction(lazyModule, m => m.replyByMeeting);
export const lazyToggleUndoDarkMode = new LazyAction(lazyModule, m => m.toggleUndoDarkMode);
export const lazySetHidePopout = new LazyAction(lazyModule, m => m.setHidePopout);
export const lazyCreateThreadSummarizationViewState = new LazyAction(
    lazyModule,
    m => m.createThreadSummarizationViewState
);
// Export synchronous actions
export { isItemHeldByConversationItemParts, isItemHeldByItemReadingPane } from './utils/isItemHeld';

// Export store and schema
export type { default as ItemViewState } from './store/schema/ItemViewState';
export { default as readingPaneStore } from './store/Store';
export type { default as SmartPillViewState } from './store/schema/SmartPillViewState';
export type { default as ItemReadingPaneViewState } from './store/schema/ItemReadingPaneViewState';
export type { default as ItemPartViewState } from './store/schema/ItemPartViewState';
export type { default as DeeplinkId } from './store/schema/DeeplinkId';
export type { default as ActionableMessageCardInItemViewState } from './store/schema/ActionableMessageCardInItemViewState';
export { default as getConversationReadingPaneViewState } from './utils/getConversationReadingPaneViewState';
export { default as getItemReadingPaneViewState } from './utils/getItemReadingPaneViewState';
export type { default as LoadItemOptions } from './store/schema/LoadItemOptions';
export type { default as ReadingPaneViewState } from './store/schema/ReadingPaneViewState';
