import type { Capability } from 'owa-capabilities';
import type { MailboxInfo } from 'owa-client-types';
import { isFeatureEnabled } from 'owa-feature-flags';
import { isAnalyzeContentDisabledForPrivacy } from 'owa-privacy-utils/lib/selectors/isAnalyzeContentDisabledForPrivacy';

/**
 * NOTE: Please see the README for more information on how the
 * capability functions must be implemented and used.
 */
export const translatorCapability: Capability = {
    isEnabled: (mailboxInfo?: MailboxInfo) => {
        return isFeatureEnabled('rp-inlineTranslation', mailboxInfo);
    },
    isSupported: (_mailboxInfo?: MailboxInfo) => {
        return !isAnalyzeContentDisabledForPrivacy();
    },
};
