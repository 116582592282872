import { getGroupFolderRulePermissionStore } from '../groupFolderRulePermissionStore';
import type { GroupFolderAndRulePermission } from '../schema/GroupFolderAndRulePermission';
import { getIndexerValueForMailboxInfo } from 'owa-client-types';
import type { MailboxInfo } from 'owa-client-types';
import type { GroupFolderRulePermission } from '../schema/GroupFolderRulePermissionStore';
import { ObservableMap } from 'mobx';

export function getGroupFolderAndRulePermissionTable(mailboxInfo: MailboxInfo) {
    const index = getIndexerValueForMailboxInfo(mailboxInfo);
    const folderRulePermission =
        getGroupFolderRulePermissionStore().groupFolderRulePermissionByMailboxInfo.get(index);

    if (!folderRulePermission) {
        const defaultGroupFolderRulePermission: GroupFolderRulePermission = {
            folderRulePermissionTable: new ObservableMap<string, GroupFolderAndRulePermission>({}),
        };

        getGroupFolderRulePermissionStore().groupFolderRulePermissionByMailboxInfo.set(
            index,
            defaultGroupFolderRulePermission
        );

        return defaultGroupFolderRulePermission.folderRulePermissionTable;
    }

    return folderRulePermission.folderRulePermissionTable;
}

export function getGroupFolderAndRulePermission(
    mailboxInfo: MailboxInfo,
    groupId: string | null | undefined
): GroupFolderAndRulePermission | null | undefined {
    if (!groupId) {
        return null;
    }

    return getGroupFolderAndRulePermissionTable(mailboxInfo).get(groupId.toLowerCase());
}
