import { isFeatureEnabled } from 'owa-feature-flags';
import isConsumer from 'owa-session-store/lib/utils/isConsumer';
import { getFromStoreOrLocalStorageOrStartupData } from '../utils/getFromStoreOrLocalStorageOrStartupData';
import { isAnalyzeContentAllowedByPolicy } from './isAnalyzeContentAllowedByPolicy';

export function isAnalyzeContentDisabledForPrivacy(): boolean {
    if (!isFeatureEnabled('settings-privacySettings')) {
        return false;
    }

    return isConsumer()
        ? !getFromStoreOrLocalStorageOrStartupData('analyzeContentEnabled')
        : !isAnalyzeContentAllowedByPolicy();
}
