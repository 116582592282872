import isSMIMEItem from 'owa-smime/lib/utils/isSMIMEItem';
import type Message from 'owa-service/lib/contract/Message';
import isSenderSelf from 'owa-mail-store/lib/selectors/isSenderSelf';
import type { MailboxInfo } from 'owa-client-types';
import getUserConfiguration from 'owa-session-store/lib/actions/getUserConfiguration';

export default function shouldDisableMessageRecall(
    item: Message | undefined,
    mailboxInfo: MailboxInfo
): boolean {
    const from = item?.From?.Mailbox?.EmailAddress || '';
    const sender = item?.Sender?.Mailbox?.EmailAddress || '';
    const isSharedMailbox = !!getUserConfiguration()?.SessionSettings?.IsExplicitLogon;

    return (
        isSMIMEItem(item) ||
        !!item?.RightsManagementLicenseData ||
        !isSenderSelf(from, mailboxInfo) ||
        !isSenderSelf(sender, mailboxInfo) ||
        isSharedMailbox
    );
}
