import type { ActionId } from './actionId';
import type { TableView } from 'owa-mail-list-store';
import { getAreRowKeysInExistingAnimation } from './getAreRowKeysInExistingAnimation';
import { getShouldAnimate } from './getShouldAnimate';
import { addPendingAnimation } from '../actions/addPendingAnimation';
import { mailListItemAnimationStore } from '../store/Store';
import {
    getSelectedTableView,
    isItemPartOperation,
    MailRowDataPropertyGetter,
} from 'owa-mail-list-store';
import { selectNewItemUponRowRemoval } from 'owa-mail-actions/lib/mailListSelectionActions';
import shouldFirstRowBeSelectedOnLoad from 'owa-mail-list-store/lib/selectors/shouldFirstRowBeSelectedOnLoad';

export function startAnimationOrHandleActionAsNeeded(
    actionId: ActionId,
    rowKeys: string[],
    handleAnimation?: () => void
) {
    if (!getAreRowKeysInExistingAnimation(rowKeys)) {
        const ongoingAnimationsCount =
            mailListItemAnimationStore.activeAnimations.length +
            mailListItemAnimationStore.pendingAnimations.length;
        if (getShouldAnimate() && ongoingAnimationsCount < 3) {
            const tableView = getSelectedTableView();

            // Select the next row immediately so that we don't have to wait for the animation to complete.
            // However, we should not auto-select when:
            // 1) Is item part operation because it is a less common scenario for users to want to do rapid-fire-triage
            // 2) It's an add row animation because we don't want to change selection when a new row is being inserted into the ML
            // 3) The setting selected is "let me select which message to read first" + no selectedRowKeys in tableView
            // 4) Any one of the rows being removed isn't in the tableView's selectedRowKeys (I'm just checking the 1st row in rowKeys)
            const shouldNotAutoSelect =
                isItemPartOperation() ||
                actionId === 'AddRowInitial' ||
                (!shouldFirstRowBeSelectedOnLoad(tableView) &&
                    tableView.selectedRowKeys.size === 0) ||
                !tableView.selectedRowKeys.has(rowKeys[0]);
            if (!shouldNotAutoSelect) {
                trySelectNextOrPreviousRow(rowKeys, tableView);
            }
            addPendingAnimation(rowKeys, actionId /*actionId*/, handleAnimation);
        } else {
            handleAnimation?.();
        }
    }
    //no-op if any of the rowKeys are in an ongoing animation
}

function trySelectNextOrPreviousRow(rowKeys: string[], tableView: TableView) {
    const keyOfLastRowSelected = rowKeys[rowKeys.length - 1]; // This is the last row that the user selected, not necessarily the row with the highest index.

    if (keyOfLastRowSelected) {
        const indexOfLastRowSelected = tableView.rowKeys.indexOf(keyOfLastRowSelected);

        const lastSelectedRowWasPinned = MailRowDataPropertyGetter.getIsPinned(
            keyOfLastRowSelected,
            tableView
        );

        selectNewItemUponRowRemoval(
            tableView,
            indexOfLastRowSelected,
            lastSelectedRowWasPinned,
            true /*isRowNotRemovedYet*/
        );
    }
}
